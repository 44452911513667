import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import NavSearch from 'components/Layout/NavSearch';
import { getContacts } from 'lib/contact';
import { contactOptions } from 'components/options';
import { useFormatDate } from 'hooks';
import { sortDate } from 'utils';
import { Timestamp } from 'firebase/firestore';

export default function Contacts() {
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);
  const [contactList, setContactList] = useState([])
  const { isAdmin } = useSelector(
    state => ({
      isAdmin: state.auth.userData.isAdmin,
    }),
    shallowEqual
  );
  const fetchContact = async() => {
    await getContacts(setContactList, setLoading)
  }
  useEffect(() => {

    if (isAdmin) {
      fetchContact()
    }
  }, [isAdmin]);

  const redirect = !isAdmin && <Navigate to={paths.ROOT} />;

  const columns = [
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ row }) => (contactOptions.filter((val, index) => val.value === row.original.type)[0]?.label)
    },
    {
      Header: 'Subject',
      accessor: 'subject',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          { row.original.createdAt instanceof Timestamp && useFormatDate(row.original.createdAt.toDate(), {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </small>
      )
    },
  ];

  const data = search
    ? contactList.filter((el) => {
        const clonedElem = { email: el.email, subject: el.subject, type: el.type };
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase()),
        );
      })
    : contactList;

  return (
    <>
      {redirect}

      <Card>
        <Card.Header>
          <div className="d-flex flex-row align-items-center">
            <Card.Title as="h5">Manage Contact </Card.Title>
            <NavSearch handleChange={setSearch} />
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} style={{
              overflowX: 'auto',
              width: '100%',
              display: 'block'
            }}/>}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
