import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import {
  doc,
  collection,
  getDocs,
  deleteDoc,
  updateDoc,
  getDoc,
  addDoc,
  serverTimestamp,
  query,
  orderBy
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, deleteObject, uploadBytes } from 'firebase/storage';
import {
  getAuth,
  sendSignInLinkToEmail
} from 'firebase/auth';
import { db } from 'firebase.js';
import { checkUserData } from './auth';

export const CUSTOMER_FETCH_DATA_INIT = createAction('CUSTOMER_FETCH_DATA_INIT');
export const CUSTOMER_FETCH_DATA_SUCCESS = createAction(
  'CUSTOMER_FETCH_DATA_SUCCESS'
);
export const CUSTOMER_FETCH_DATA_FAIL = createAction('CUSTOMER_FETCH_DATA_FAIL');

export const CUSTOMER_DELETE_INIT = createAction('CUSTOMER_DELETE_INIT');
export const CUSTOMER_DELETE_SUCCESS = createAction(
  'CUSTOMER_DELETE_SUCCESS'
);
export const CUSTOMER_DELETE_FAIL = createAction('CUSTOMER_DELETE_FAIL');

export const CUSTOMER_CLEAR_DATA = createAction('CUSTOMER_CLEAR_DATA');

export const CUSTOMER_CREATE_INIT = createAction('CUSTOMER_CREATE_INIT');
export const CUSTOMER_CREATE_SUCCESS = createAction(
  'CUSTOMER_CREATE_SUCCESS'
);
export const CUSTOMER_CREATE_FAIL = createAction('CUSTOMER_CREATE_FAIL');

export const CUSTOMER_MODIFY_INIT = createAction('CUSTOMER_MODIFY_INIT');
export const CUSTOMER_MODIFY_SUCCESS = createAction(
  'CUSTOMER_MODIFY_SUCCESS'
);
export const CUSTOMER_MODIFY_FAIL = createAction('CUSTOMER_MODIFY_FAIL');

export const CUSTOMER_CLEAN_UP = createAction('CUSTOMER_CLEAN_UP');

export const CUSTOMER_CLEAR_DATA_LOGOUT = createAction('CUSTOMER_CLEAR_DATA_LOGOUT');

const storage = getStorage();
const auth = getAuth();
const functions = getFunctions();

export const fetchUsers = (all = true) => {
  return async (dispatch) => {
    dispatch(checkUserData());

    dispatch(CUSTOMER_FETCH_DATA_INIT());

    let users = [];

    try {
      const querySnapshot = await getDocs(query(collection(db, 'customers'), orderBy("createdAt", "desc")));
      querySnapshot.forEach((doc) => {
        users.push({
          id: doc.id,
          ...doc.data()
        });
      });
      return dispatch(
        CUSTOMER_FETCH_DATA_SUCCESS({
          users: users
        })
      );
    } catch (error) {
      toastr.error('', error);
      return dispatch(CUSTOMER_FETCH_DATA_FAIL({ error }));
    }
  };
};

const deleteLogo = oldLogo => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo
    .split('customers%2F')
    .pop()
    .split('?alt=media')
    .shift();
  const desertRef = ref(storage, logoPath);
  return deleteObject(desertRef).then(() => {
    // File deleted successfully
  }).catch(() => {
    // Uh-oh, an error occurred!
  });
};

export const deleteUser = id => {
  return async (dispatch, getState) => {
    dispatch(CUSTOMER_DELETE_INIT());
    const { locale } = getState().preferences;
    const { photoURL } = getState()
      .customers.data.filter(user => user.id === id)
      .pop();

    const deleteLogoTask = photoURL ? deleteLogo(photoURL) : null;

    const deleteUserTask = await deleteDoc(doc(db, 'customers', id));

    try {
      await Promise.all([deleteLogoTask, deleteUserTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CUSTOMER_DELETE_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'The customer was deleted.');
    return dispatch(CUSTOMER_DELETE_SUCCESS({ id }));
  };
};

export const clearUsersData = () => {
  return dispatch => {
    dispatch(CUSTOMER_CLEAR_DATA());
  };
};

export const clearUsersDataLogout = () => {
  return dispatch => {
    dispatch(CUSTOMER_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (uid, file) => {

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;
  const storageRef = ref(storage, `customers/${fileName}`);
  uploadBytes(storageRef, file).then((snapshot) => {
    console.log('Uploaded a blob or file!');
  });

};

const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/customers%2F${uid}_200x200.${fileExtension}?alt=media`;
};

export const createUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(CUSTOMER_CREATE_INIT());
    const { locale } = getState().preferences;

    let response;
    const { email, firstName, lastName, phoneNumber, active } = data;
    try {
      const createUserAuth = httpsCallable(functions, 'createUser');

      const postData = {
        email,
        firstName,
        lastName,
        disabled: !active,
        userType: 'customer'
      };
      if (phoneNumber) {
        postData.phoneNumber = phoneNumber;
      }
      console.log(postData);
      response = await createUserAuth(postData);
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CUSTOMER_CREATE_FAIL({
          error: errorMessage
        })
      );
    }

    const actionCodeSettings = {
      url: process.env.REACT_APP_LOGIN_PAGE_URL,
      handleCodeInApp: true
    };

    try {
      await sendSignInLinkToEmail(auth, data.email, actionCodeSettings);
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CUSTOMER_CREATE_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'Customer is created successfully');
    return dispatch(CUSTOMER_CREATE_SUCCESS({ user: response.data }));
  };
};

export const modifyUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(CUSTOMER_MODIFY_INIT());
    // eslint-disable-next-line no-param-reassign
    delete data.isProfile;
    // eslint-disable-next-line no-param-reassign
    delete data.isEditing;

    const { locale } = getState().preferences;
    const { photoURL } = getState()
      .customers.data.filter(user => user.id === data.id)
      .pop();

    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (data.file) {
      newLogoUrl = getLogoUrl(data.id, data.file);
      deleteLogoTask = photoURL && deleteLogo(photoURL);
      uploadLogoTask = uploadLogo(data.id, data.file);
    }

    const userData = {
      ...data,
      photoURL: newLogoUrl || photoURL || null
    };
    delete userData.file;

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask]);
      const updateUserAuth = httpsCallable(functions, 'updateUser');
      const postData = {
        uid: data.id,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        disabled: !data.active,
        photoURL: userData.photoURL,
        userType: 'customer'
      };
      if (data.phoneNumber) {
        postData.phoneNumber = data.phoneNumber;
      }
      await updateUserAuth(postData);
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CUSTOMER_MODIFY_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'Customer is updated successfully');

    return dispatch(CUSTOMER_MODIFY_SUCCESS({ user: { ...userData, id: data.id } }));
  };
};

export const updateCredit = (data) => {
  return async (dispatch, getState) => {
    dispatch(CUSTOMER_MODIFY_INIT());
    const { locale } = getState().preferences;

    const { id, credit } = data;

    try {
      const ref = doc(db, 'customers', id);
      const user = await getDoc(ref);

      await updateDoc(ref, { credit });
      const userCredit = user.data()?.credit ?? 0;
      const amount = Number(credit) - Number(userCredit);
      console.log(amount);
      if (amount !== 0) {
        const transaction = {
          type: 'admin_update',
          amount: Number(amount),
          customerId: id,
          createdAt: serverTimestamp()
        };
        if (amount > 0) {
          transaction.description = `Admin added ${Math.abs(Number(amount))} credits`;
        }
        if (amount < 0) {
          transaction.description = `Admin took ${Math.abs(Number(amount))} credits`;
        }
        console.log(transaction);
        await addDoc(collection(db, 'transactions'), transaction);
      }
      console.log(user);
      toastr.success('', 'Credit is updated successfully');
      return dispatch(CUSTOMER_MODIFY_SUCCESS({ user: { ...user.data() } }));
    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CUSTOMER_MODIFY_FAIL({
          error: errorMessage
        })
      );
    }
  };
};

export const fetchSubscription = (id) => {

};

export const usersCleanUp = () => dispatch => dispatch(CUSTOMER_CLEAN_UP());
